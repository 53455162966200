
import { 
  SET_CURRENT_URL, 
  CLICKED_FOOTER_MERCHANT_FEATURES, 
  CLICKED_FOOTER_TABS_NOT_ON_HEADER 
} from "../Types";


export const setCurrentUrl = (currentUrl) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_URL,
    payload: currentUrl,
  });
};


export const setClickedMerchantFeatures = (clickedMF) => (dispatch) => {
  dispatch({
    type: CLICKED_FOOTER_MERCHANT_FEATURES,
    payload: clickedMF,
  });
};


export const setClickedFooterTabsNotOnHeader = (clickedNotOnHeader) => (dispatch) => {
  dispatch({
    type: CLICKED_FOOTER_TABS_NOT_ON_HEADER,
    payload: clickedNotOnHeader,
  });
};
