// external modules
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Media from "react-media";

// internal modules
import { setRegion } from "src/Common/Redux/Actions";
import { Menu } from "src/Common/Theme/Typography";
import { setCurrentUrl } from "src/RootRedux/UrlRedux/Actions";
import { regionGetter } from "src/Services/RegionGetter";
import SeaMoneyLogo from "./Assets/Imgs/seamoney-logo.png";
import SPayLogo from "./Assets/Imgs/sppay-logo.png";
import { headerMenu, headerMerchantSubMenu } from "./Constants";

import "./style.less";

export const AppHeader = () => {
  const dispatch = useDispatch();
  const region = regionGetter();

  // hooks for checking pathname for header menu color change
  const location = useLocation();
  const { pathname } = location;

  const currentUrlPageReducer = useSelector((state) => state.currentUrlReducer);
  const { currentUrl } = currentUrlPageReducer;

  // offCanvas for mobile
  const [isOpen, setIsOpen] = useState(false);

  const [openSubmenu, setOpenSubmenu] = useState(false);
  const debounceOpen = debounce(setOpenSubmenu, 100);

  const handleOpen = () => {
    isOpen === false ? setIsOpen(true) : setIsOpen(false);
  };

  // when app first renders, it sets the language
  const setRegionOnMount = useCallback(() => {
    dispatch(setRegion(region));
  }, [dispatch, region]);

  const onButtonClick = (path) => {
    setIsOpen(false);
    setOpenSubmenu(false);
    window.scrollTo(0, 0);
    dispatch(setCurrentUrl(path));
  };

  useEffect(() => {
    setRegionOnMount();
  }, [setRegionOnMount]);

  // useeffect for menu color change
  useEffect(() => {
    dispatch(setCurrentUrl(pathname));
  }, [location]);

  return (
    <Media
      queries={{
        medium: "(max-width: 960px)",
        large: "(min-width: 961px)",
      }}
    >
      {(matches) => (
        <>
          {matches.large && (
            <div className="AppHeader">
              <div className="AppHeader__Contents">
                <div className="AppHeader__Contents__Logo">
                  <Link to="/" onClick={() => onButtonClick("/")}>
                    <img
                      src={SeaMoneyLogo}
                      alt="SeaMoneyLogo"
                      className="AppHeader__Contents__shopeeLogo"
                    />
                  </Link>
                  <Link to="/" onClick={() => onButtonClick("/")}>
                    <img
                      src={SPayLogo}
                      className="AppHeader__Contents__shopeeLogo"
                      alt="ShopeePayLogo"
                    />
                  </Link>
                </div>
                <nav>
                  <div className="AppHeader__Contents__navPrimary">
                    {headerMenu.map((menuItem) => {
                      const { name, path, children } = menuItem;
                      const selected = path && currentUrl == path;

                      return (
                        <Link
                          key={path}
                          className={`AppHeader__Contents__styledLink${
                            selected ? "__Clicked" : ""
                          }`}
                          to={path}
                          name={name}
                          onClick={() => onButtonClick(path)}
                          onMouseEnter={() => children && debounceOpen(true)}
                          onMouseLeave={() => children && debounceOpen(false)}
                        >
                          <Menu>{name}</Menu>
                        </Link>
                      );
                    })}
                  </div>
                  <div
                    className={`AppHeader__Contents__navSecondary${
                      openSubmenu ? "__open" : ""
                    }`}
                    onMouseEnter={() => debounceOpen(true)}
                    onMouseLeave={() => debounceOpen(false)}
                  >
                    {headerMerchantSubMenu.map((menuItem) => {
                      const { name, path } = menuItem;
                      const selected = path && currentUrl == path;

                      return (
                        <Link
                          key={path}
                          className={`AppHeader__Contents__styledLink${
                            selected ? "__Clicked" : ""
                          }`}
                          to={path}
                          name={name}
                          onClick={() => onButtonClick(path)}
                        >
                          <Menu>{name}</Menu>
                        </Link>
                      );
                    })}
                  </div>
                </nav>
              </div>
            </div>
          )}
          {matches.medium && (
            <div className="AppHeaderMobile">
              <div className="AppHeaderMobile__Logo">
                <Link to="/" onClick={() => onButtonClick("/")}>
                  <img
                    src={SeaMoneyLogo}
                    alt="SeaMoneyLogo"
                    className="AppHeaderMobile__shopeeLogo"
                  />
                </Link>
                <Link to="/" onClick={() => onButtonClick("/")}>
                  <img
                    src={SPayLogo}
                    className="AppHeaderMobile__shopeeLogo"
                    alt="ShopeePayLogo"
                  />
                </Link>
              </div>
              <div>
                <input
                  type="checkbox"
                  className="openSidebarMenu"
                  id="openSidebarMenu"
                  checked={isOpen}
                  onChange={handleOpen}
                />
                <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                  <div className="spinner diagonal part-1" />
                  <div className="spinner horizontal" />
                  <div className="spinner diagonal part-2" />
                </label>

                {isOpen && (
                  <div
                    className="AppHeaderMobile__menuBackground"
                    onClick={handleOpen}
                  />
                )}
                <div id="sidebarMenu">
                  <div className="sidebarMenuInner">
                    {headerMenu.map((menu) => {
                      const { name, path, children } = menu;

                      return children ? (
                        headerMerchantSubMenu.map((menu) => {
                          const { name, path } = menu;

                          return (
                            <Link
                              to={path}
                              key={path}
                              name={path}
                              onClick={() => onButtonClick(path)}
                            >
                              <li
                                key={path}
                                name={path}
                                className={`AppHeaderMobile__styledLink${
                                  currentUrl === path ? "__Clicked" : ""
                                }`}
                              >
                                {name}
                              </li>
                            </Link>
                          );
                        })
                      ) : (
                        <Link
                          to={path}
                          key={path}
                          name={path}
                          onClick={() => onButtonClick(path)}
                        >
                          <li
                            key={path}
                            name={path}
                            className={`AppHeaderMobile__styledLink${
                              currentUrl === path ? "__Clicked" : ""
                            }`}
                          >
                            {name}
                          </li>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Media>
  );
};
