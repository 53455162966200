// header menu
// commented out promotion menu until we activate in next phase [MAR.02.2022]
export const headerMerchantSubMenu = [
  { name: "Be a Merchant", path: "merchant" },
];

export const headerMenu = [
  { name: "Home", path: "/" },
  { name: "About Us", path: "/about-us" },
  { name: "ShopeePay", path: "/shopeepay" },
  { name: "SPayLater", path: "/spaylater" },
  {
    name: "Merchant",
    path: "/merchant",
    children: headerMerchantSubMenu,
  },
];

// language toggle value string since local storage value does not take boolean value

export const TRUE = "TRUE";
export const FALSE = "FALSE";
